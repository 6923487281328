/**
 * CToolMergeExport
 * @author Tevin
 * @tutorial
 *   默认请求频率：0.55(平均请求时间) + 0.1(请求间隔) + 0.05(页面处理延迟) = 0.7 秒/次
 *   限速请求频率：0.55(平均请求时间) + 2.5(请求间隔) + 0.05(页面处理延迟) = 3.0 秒/次
 *   随着业务数据不断累计，请求时间会不断延长，频率一般会更慢
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import innerText from 'react-innertext';
import { Button, Modal, Tooltip, Table, Progress, message } from 'antd';
import { ExportOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { CItemEditor } from '@components/plugins/itemEditor/CItemEditor';
import { CText } from '@components/fragments/text/CText';
import './cToolMergeExport.scss';

export class CToolMergeExport extends React.Component {
    static propTypes = {
        // 图标悬浮提示
        tipTitle: PropTypes.string,
        // 占位图标
        icon: PropTypes.node,
        // 导出文件名
        fileName: PropTypes.string,
        // 导出每页大小
        pageSize: PropTypes.number,
        // 列设置
        columns: PropTypes.array,
        // 扩展列
        extraColumns: PropTypes.array,
        // 操作权限
        permission: PropTypes.bool,
        // 发起导出
        onExport: PropTypes.func,
    };

    static defaultProps = {
        tipTitle: '导出全部页面',
        fileName: '表格',
        columns: [],
        extraColumns: [],
        pageSize: 100,
        permission: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            exportLoading: false,
            exportFinish: false,
            dataResource: [],
            pagination: {
                current: 0,
                pageSize: this.props.pageSize,
                total: 0,
            },
            loadPercent: 0,
        };
        this._data = {
            // 平化的列设置
            columnsFlat: [],
            // 字段名称列队
            fields: [],
            // 表格数据
            datas: [],
            // 表格内容类型
            contentTypes: [],
            // 基础加载间隔
            loadGap: 0,
            loadGapType: [100, 2500],
        };
        this.$refs = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _checkTimeLimitMode() {
        const hour = moment().hour();
        let allow = false;
        if (hour < 9) {
            allow = true;
        } else if (13 <= hour && hour <= 15) {
            allow = true;
        } else if (hour >= 20) {
            allow = true;
        }
        this._data.loadGap = this._data.loadGapType[allow ? 0 : 1];
        return allow;
    }

    _beforeExport() {
        if (this.state.exportLoading) {
            return;
        }
        // 初始化总数据条数
        this.setState({
            exportLoading: true,
            timeAllow: this._checkTimeLimitMode(),
            pagination: {
                ...this.state.pagination,
                current: 0,
                total: this.props.total,
            },
        });
        // 动画
        setTimeout(() => {
            this.setState({
                innerShow: true,
            });
        }, 100);
    }

    _getExportPage(pageCallback, recursion = 0) {
        const nextPage = this.state.pagination.current + 1;
        const totalPage = Math.ceil(
            parseInt(this.state.pagination.total) / this.state.pagination.pageSize,
        );
        // 超过页码，终止
        if (nextPage > totalPage) {
            return pageCallback('end');
        }
        // 获取数据
        this.props.onExport({
            pagination: {
                ...this.state.pagination,
                current: nextPage,
            },
            callback: res => {
                // 请求失败时
                if (!res) {
                    // 重复三次后弹窗询问
                    if (recursion > 3) {
                        Modal.confirm({
                            title: '网络故障！',
                            content: '网络出现故障，是否继续导出？',
                            okText: '继续导出',
                            onOk: () => {
                                this._getExportPage(pageCallback);
                            },
                            onCancel: () => {
                                setTimeout(() => {
                                    this._handleClose();
                                }, 100);
                            },
                        });
                    } else {
                        setTimeout(() => {
                            this._getExportPage(pageCallback, ++recursion);
                        }, 200);
                    }
                    return;
                }
                // 请求成功
                else {
                    // 空数据判断
                    if (
                        res.total === 0 ||
                        (nextPage === 1 && res.dataResource.length === 0)
                    ) {
                        Modal.warning({
                            title: '无数据',
                            content: (
                                <div>
                                    当前查询条件下，暂无数据可以导出！
                                    <br />
                                    <CText type="ignore">（请更改查询条件后再试）</CText>
                                </div>
                            ),
                            onOk: () => {
                                setTimeout(() => {
                                    this._handleClose();
                                }, 100);
                            },
                        });
                        return;
                    }
                    // 数据正常
                    this.setState({
                        dataResource: res.dataResource,
                        pagination: {
                            ...this.state.pagination,
                            current: nextPage,
                            total: res.total,
                        },
                    });
                    if (this._checkRowsLimit(res.total) === 'stop') {
                        pageCallback(-1);
                    } else {
                        pageCallback(nextPage);
                    }
                }
            },
        });
    }

    _createRowsData() {
        // const mulNumberReg = /^\d+(,\d+)+$/;
        // const longNumberReg = /^\d{16,}$/;
        const tableData = [];
        this.$refs.container
            .querySelector('.ant-table-tbody')
            .querySelectorAll('.ant-table-row')
            .forEach(row => {
                const rowData = [];
                row.querySelectorAll('.ant-table-cell').forEach(cell => {
                    // const text = cell.innerText || cell.textContent;
                    // // 多个数字逗号拼接的字符串或者长度超过15位的纯数字，末尾加一个逗号，防止 excel 转换为科学计数法，丢失后段内容
                    // if (mulNumberReg.test(text) || longNumberReg.test(text)) {
                    //     rowData.push(text + ',');
                    // } else {
                    //     rowData.push(text);
                    // }
                    rowData.push(cell.innerText || cell.textContent);
                });
                tableData.push(rowData);
            });
        const rows = tableData.map(() => []);
        this._data.columnsFlat.forEach((column, index) => {
            // 非数据列跳过
            if (!column.dataIndex) {
                return;
            }
            tableData.forEach((rowData, rowIndex) => {
                rows[rowIndex].push(rowData[index]);
            });
        });
        return rows;
    }

    _transProcess(step) {
        const total = parseInt(this.state.pagination.total || this.props.total);
        // 总页数
        const pageTotal = Math.ceil(total / 100);
        // 进度百分比
        const percent = ((step / pageTotal) * 100).toFixed(2);
        // 剩余时间
        const surplus = (pageTotal - step) * (this._data.loadGap + 50 + 550);
        const duration = moment.duration(surplus);
        const long = {
            hour: parseInt(duration.asHours()),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
        };
        long.seconds = Math.max(long.seconds, 0);
        let timeWord = '大约';
        if (long.hour > 0) {
            timeWord += ' ' + long.hour + ' 小时 ' + long.minutes + ' 分';
        } else if (long.minutes > 0) {
            timeWord += ' ' + long.minutes + ' 分 ' + long.seconds + ' 秒';
        } else {
            timeWord += ' ' + long.seconds + ' 秒';
        }
        // 显示
        this.setState({
            loadPercent: Number(percent),
            timeSurplus: timeWord,
        });
    }

    _checkRowsLimit(total) {
        // 总数超过20万条，阻止导出
        if (total > 20 * 10000) {
            Modal.confirm({
                title: '导出失败！',
                content: '您选择的导出数据过多，请筛选后再导出！',
                okText: '知道了',
                okType: 'primary',
                onOk: closer => {
                    closer();
                    setTimeout(() => {
                        this._handleClose();
                    }, 100);
                },
            });
            return 'stop';
        }
        return 'pass';
    }

    _hanldeExport() {
        if (!this.props.permission) {
            message.error('您没有导出此数据的权限！');
            return;
        }
        if (this._checkRowsLimit(this.props.total) === 'stop') {
            return;
        }
        this._beforeExport();
        const getPageData = () => {
            // 延迟一个间隔再加载
            setTimeout(() => {
                if (!this.state.exportLoading) {
                    return;
                }
                this._getExportPage(step => {
                    // 加载过程
                    if (step >= 0) {
                        this._transProcess(step);
                        setTimeout(() => {
                            if (!this.state.exportLoading) {
                                return;
                            }
                            this._createRowsData().forEach(row => {
                                // 反序添加
                                // 后端先反序，按从早到晚的顺序发送数据，前端再反序，顺序回正
                                // 用于解决导出过程中产生新数据的问题
                                this._data.datas.unshift(row);
                            });
                            // 下一页
                            getPageData();
                        }, 50);
                    }
                    // 加载终止
                    else if (step < 0) {
                        this._transProcess(0);
                    }
                    // 加载完成
                    else if (step === 'end') {
                        this.setState({
                            exportFinish: true,
                        });
                        // 完成时关闭询问窗口
                        this.$refs.confirmClose && this.$refs.confirmClose.destroy();
                    }
                });
            }, this._data.loadGap);
        };
        this._transProcess(0);
        getPageData();
    }

    _hanldeDownload() {
        const mulNumberReg = /^\d+(,\d+)+$/;
        const longNumberReg = /^\d{16,}$/;
        // 创建CSV
        let csv = this._data.fields.join(',') + '\r\n';
        this._data.datas.forEach(row => {
            const rowArr = [];
            row.forEach((col, index) => {
                let content = (col || '').replace(/^\s+|"|\s+$/g, '');
                // 多个数字逗号拼接的字符串或者长度超过15位的纯数字，末尾加一个逗号，防止 excel 转换为科学计数法，丢失后段内容
                if (mulNumberReg.test(content) || longNumberReg.test(content)) {
                    content += ',';
                }
                // 普通内容列
                if (this._data.contentTypes[index] === 'normal') {
                    rowArr.push('"' + content + '"');
                }
                // 强制转字符串列，不允许 Excel 转数值
                else if (this._data.contentTypes[index] === 'string') {
                    // 如果内容已有逗号，不转换（否则 Excel 将视为列分割符）
                    if (content.indexOf(',') >= 0) {
                        rowArr.push('"' + content + '"');
                    }
                    // 没有逗号，增加等号强制转换
                    else {
                        rowArr.push('="' + content + '"');
                    }
                }
            });
            csv += rowArr.join(',') + '\r\n';
        });
        // 下载
        const bom = '\uFEFF';
        const blob = new Blob([bom + csv], {
            type: 'text/csv,charset=UTF-8',
            endings: 'native',
        });
        const URL = window.URL || window.webkitURL;
        const csvUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        const date = moment().format('YYYYMMDD.HHmm');
        link.href = csvUrl;
        link.download = this.props.fileName + '-' + date + '.csv';
        link.click();
    }

    _handleClose() {
        this._data.datas = [];
        // 动画
        this.setState({
            innerShow: false,
        });
        setTimeout(() => {
            this.setState({
                exportLoading: false,
                exportFinish: false,
                timeAllow: false,
                pagination: {
                    ...this.state.pagination,
                    current: 0,
                    total: this.props.total,
                },
            });
        }, 200);
    }

    _flatteningColumns() {
        const defaultCol = {
            sorter: false,
            fixed: false,
        };
        this._data.columnsFlat = [];
        this.props.columns.forEach(column => {
            if (!column.children || column.children.length === 0) {
                this._data.columnsFlat.push({
                    ...column,
                    ...defaultCol,
                });
                // 补充扩充的列
                (this.props.extraColumns || []).forEach(col => {
                    if (column.dataIndex === col.afterIndex) {
                        this._data.columnsFlat.push({
                            ...col,
                            ...defaultCol,
                        });
                    }
                });
            }
            // 存在子级的列
            else {
                column.children.forEach(child => {
                    this._data.columnsFlat.push({
                        ...child,
                        ...defaultCol,
                        // 将两层 title 合并
                        title: '<' + column.title + '>\n' + child.title,
                    });
                    // 补充扩充的列
                    (this.props.extraColumns || []).forEach(col => {
                        if (child.dataIndex === col.afterIndex) {
                            this._data.columnsFlat.push({
                                ...col,
                                ...defaultCol,
                                // 将两层 title 合并
                                title: '<' + column.title + '>\n' + col.title,
                            });
                        }
                    });
                });
            }
        });
        // 提取字段名称和内容类型
        this._data.fields = [];
        this._data.contentTypes = [];
        this._data.columnsFlat.forEach(column => {
            // 非数据列跳过
            if (!column.dataIndex) {
                return;
            }
            this._data.fields.push('"' + innerText(column.title) + '"');
            this._data.contentTypes.push(column.export || 'normal');
        });
    }

    _renderTable() {
        this._flatteningColumns();
        if (!this.state.exportLoading) {
            return null;
        }
        return (
            <div
                className="c-merge-export-table"
                ref={elm => (this.$refs.container = elm)}
            >
                <Table
                    columns={this._data.columnsFlat}
                    rowKey={row => row.id || row.key}
                    dataSource={this.state.dataResource}
                    showSorterTooltip={false}
                    pagination={false}
                />
            </div>
        );
    }

    _renderExplanation() {
        return (
            <div>
                <p>
                    为了避免导出操作对业务运行产生冲击，影响业务的稳定性，在
                    <b>高峰时段将对导出的速度进行限制</b>！
                </p>
                <br />
                <p>
                    高峰时间段一般指：
                    <br />
                    每天 <b>09:00 ~ 12:59</b>
                    <br />
                    每天 <b>16:00 ~ 19:59</b>
                </p>
                <br />
                <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={evt => this.$refs.explain.$close()}>
                        了解了
                    </Button>
                </div>
                <br />
            </div>
        );
    }

    _renderConfirmClose() {
        const msgs = [
            ['是否终止导出？', '终止导出将会丢弃已处理进度'],
            ['是否关闭导出？', '关闭后已处理数据将被清空，下次仍需要重新导出'],
        ];
        this.$refs.confirmClose = Modal.confirm({
            title: msgs[this.state.exportFinish ? 1 : 0][0],
            content: msgs[this.state.exportFinish ? 1 : 0][1],
            okText: this.state.exportFinish ? '确认关闭' : '确认终止',
            okType: 'danger',
            onOk: closer => {
                closer();
                setTimeout(() => {
                    this._handleClose();
                }, 100);
            },
        });
    }

    _renderProcess() {
        if (!this.state.exportLoading) {
            return null;
        }
        return (
            <div className="c-merge-export-process">
                <div className={['inner ', this.state.innerShow ? 'on' : ''].join(' ')}>
                    <div className="icon">
                        {this.state.exportFinish ? (
                            <CheckOutlined />
                        ) : (
                            <img src="/static/assets/images/loading-200.gif" alt="" />
                        )}
                    </div>
                    <div className="right">
                        <h2 className="title">
                            <strong>
                                {this.state.exportFinish ? '导出已就绪' : '正在导出'}
                            </strong>
                            <CText
                                className="tips"
                                type="danger"
                                style={{ display: this.state.timeAllow ? 'none' : '' }}
                            >
                                当前正处于
                                <CItemEditor
                                    width={400}
                                    title="关于导出限速"
                                    content={this._renderExplanation()}
                                    iconType="viewer"
                                    ref={elm => (this.$refs.explain = elm)}
                                >
                                    导出限速
                                </CItemEditor>
                                时间段内，将花费较长时间！
                            </CText>
                        </h2>
                        <div className="progress">
                            <Progress percent={this.state.loadPercent} />
                            {this.state.exportFinish ? (
                                <div>请下载文档</div>
                            ) : (
                                <div className="tips">
                                    剩余时间：{this.state.timeSurplus}
                                </div>
                            )}
                        </div>
                        <div className="btn">
                            <Button
                                danger={!this.state.exportFinish}
                                onClick={evt => this._renderConfirmClose()}
                            >
                                {this.state.exportFinish ? '关闭' : '终止'}导出
                            </Button>
                            <Button
                                type="primary"
                                disabled={!this.state.exportFinish}
                                onClick={evt => this._hanldeDownload()}
                            >
                                下载文档
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="c-merge-export">
                <Tooltip
                    title={this.props.tipTitle}
                    mouseEnterDelay={0}
                    trigger={['hover', 'click']}
                >
                    <div>
                        <Button
                            size="small"
                            style={{ display: this.state.exportLoading ? 'none' : '' }}
                            onClick={evt => this._hanldeExport()}
                        >
                            {this.props.icon ? (
                                this.props.icon
                            ) : (
                                <ExportOutlined className="c-table-merge-export" />
                            )}
                        </Button>
                        <Button
                            size="small"
                            style={{ display: this.state.exportLoading ? '' : 'none' }}
                        >
                            <LoadingOutlined spin />
                        </Button>
                    </div>
                </Tooltip>
                {this._renderTable()}
                {this._renderProcess()}
            </div>
        );
    }
}
