/**
 * CToolFilter
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Popover, Tree, Checkbox } from 'antd';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';
import { CText } from '@components/fragments/text/CText';
import { Tools } from '@components/common/Tools';
import './cToolFilter.scss';

export class CToolFilter extends React.Component {
    static propTypes = {
        // 表格名称
        tableName: PropTypes.string,
        // 表格列
        columns: PropTypes.array,
        // 变化回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        columns: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            working: false,
            checkedAll: true,
            sorted: false,
            maxHeight: 0,
            displayTree: [],
        };
        this._data = {
            storageName: '',
            defaultTree: [],
            defaultCols: [],
            tree: [],
            hiddens: [],
        };
    }

    componentDidMount() {
        let tableName = this.props.tableName;
        if (!tableName) {
            const pathArr = window.location.pathname.split('/');
            const pageNames = pathArr[pathArr.length - 1].split('.');
            tableName = pageNames.length <= 2 ? pageNames[0] : pageNames[1];
        }
        this._data.storageName = 'AiSim@tableFilter&' + tableName;
        this._initFilterData();
        this.setState({
            maxHeight: parseInt(window.innerHeight * 0.65),
        });
    }

    componentWillUnmount() {}

    _initFilterData() {
        const filterData = JSON.parse(
            window.localStorage[this._data.storageName] ||
                '{"cd":0,"hiddens":[],"tree":[]}',
        );
        if (filterData.hiddens.length > 0 || filterData.tree.length > 0) {
            this.setState({
                working: true,
                checkedAll: filterData.hiddens.length === 0,
                sorted: filterData.tree.length > 0,
            });
        }
        const { defaultTree, defaultCols } = this._getDefaultData();
        this._data.defaultTree = defaultTree;
        this._data.defaultCols = defaultCols;
        this._data.tree = this._combineTrees(filterData.tree, defaultTree);
        this._data.hiddens = this._combineHiddens(filterData.hiddens, defaultCols);
        this._updateDisplayTree();
        this._updateColumns();
        // 上次记录已超过3天，重新记录
        const nowtime = Date.now();
        const lastCD = filterData.cd || 0;
        if (nowtime - lastCD > 3 * 24 * 60 * 60 * 1000) {
            this._saveFilterData();
            setTimeout(() => {
                this._cleanOverdue();
            }, 100);
        }
    }

    _saveFilterData() {
        this._data.defaultTree.forEach(item => {
            delete item.using;
            if (item.children && item.children.length > 0) {
                item.children.forEach(child => {
                    delete child.using;
                });
            }
        });
        const defaultTreeStr = JSON.stringify(this._data.defaultTree);
        const treeStr = JSON.stringify(this._data.tree);
        const data = {
            cd: Date.now(),
            hiddens: this._data.hiddens,
            tree: treeStr === defaultTreeStr ? [] : this._data.tree,
        };
        if (data.hiddens.length > 0 || data.tree.length > 0) {
            window.localStorage[this._data.storageName] = JSON.stringify(data);
        } else {
            // 没有数据时，删除此 filter 缓存
            window.localStorage.removeItem(this._data.storageName);
        }
        // 设置显示状态
        this.setState({
            working: data.hiddens.length > 0 || data.tree.length > 0,
            checkedAll: data.hiddens.length === 0,
            sorted: data.tree.length > 0,
        });
    }

    // 清理过期缓存
    _cleanOverdue() {
        const storage = window.localStorage;
        const dayLong = 24 * 60 * 60 * 1000;
        const nowTime = Date.now();
        Object.keys(storage).forEach(key => {
            if (key.indexOf('AiSim@tableFilter') >= 0) {
                const filterData = JSON.parse(storage[key]);
                // 超过 180 天未更新，视为不再使用，清理此 filter 缓存
                if (nowTime - filterData.cd > 180 * dayLong) {
                    window.localStorage.removeItem(key);
                }
            }
        });
    }

    _getColumnDataIndex(column) {
        if (column.dataIndex) {
            return column.dataIndex;
        } else {
            const titleCode = encodeURI(column.title).replace(/%/g, '');
            return titleCode.length > 36 ? titleCode.substring(0, 36) : titleCode;
        }
    }

    _getDefaultData() {
        const tree = [];
        const cols = [];
        this.props.columns.forEach(column => {
            const colValue = this._getColumnDataIndex(column);
            cols.push(colValue);
            // 普通列（没有子级）
            if (!column.children || column.children.length === 0) {
                tree.push({
                    key: colValue,
                });
            }
            // 存在子级的列
            else {
                const col = {
                    key: colValue,
                    children: [],
                };
                column.children.forEach(child => {
                    const childValue = this._getColumnDataIndex(child);
                    cols.push(childValue);
                    col.children.push({
                        key: childValue,
                    });
                });
                tree.push(col);
            }
        });
        return { defaultTree: tree, defaultCols: cols };
    }

    _combineTrees(storageTree, defaultTree) {
        // 树数据为空时，获取默认树数据
        if (storageTree.length === 0) {
            return JSON.parse(JSON.stringify(defaultTree));
        }
        // 数据合并：去除无效列
        const nextTree = [];
        storageTree.forEach(storageItem => {
            const defaultItem = defaultTree.find(
                defaultItem => defaultItem.key === storageItem.key,
            );
            if (defaultItem) {
                defaultItem.using = true;
                // 无子级
                if (!storageItem.children || storageItem.children.length === 0) {
                    nextTree.push({
                        key: storageItem.key,
                    });
                }
                // 存在子级列
                else {
                    const nextItem = {
                        key: storageItem.key,
                        children: [],
                    };
                    storageItem.children.forEach(storageChild => {
                        const defaultChild = defaultItem.children.find(
                            defaultChild => defaultChild.key === storageChild.key,
                        );
                        if (defaultChild) {
                            defaultChild.using = true;
                            nextItem.children.push(storageChild);
                        }
                    });
                    nextTree.push(nextItem);
                }
            }
        });
        // 数据合并：添加新增列
        defaultTree.forEach((defaultItem, index1) => {
            // 无子级
            if (!defaultItem.children || defaultItem.children.length === 0) {
                // 未使用，添加新增列
                if (!defaultItem.using) {
                    nextTree.splice(index1, 0, defaultItem);
                }
            }
            // 存在子级列
            else {
                // 父级未使用，直接全部添加
                if (!defaultItem.using) {
                    nextTree.splice(index1, 0, defaultItem);
                }
                // 父级有使用
                else {
                    const nextItem = nextTree.find(
                        nextItem => nextItem.key === defaultItem.key,
                    );
                    defaultItem.children.forEach((defaultChild, index2) => {
                        // 子级未使用，添加新增列
                        if (!defaultChild.using) {
                            nextItem.children.splice(index2, 0, defaultChild);
                        }
                    });
                }
            }
        });
        return nextTree;
    }

    _combineHiddens(storageHiddens, defaultCols) {
        if (storageHiddens.length === 0) {
            return storageHiddens;
        }
        // 移除失效的列
        const nextHiddens = [];
        storageHiddens.forEach(hidden => {
            if (defaultCols.findIndex(col => col === hidden) >= 0) {
                nextHiddens.push(hidden);
            }
        });
        return nextHiddens;
    }

    _updateDisplayTree() {
        const hiddens = this._data.hiddens;
        const displayTree = [];
        const checkedKeys = [];
        this._data.tree.forEach(nextItem => {
            // 未隐藏，选中
            if (hiddens.findIndex(hidden => nextItem.key === hidden) < 0) {
                checkedKeys.push(nextItem.key);
            }
            // 实际列
            const column = this.props.columns.find(
                column => this._getColumnDataIndex(column) === nextItem.key,
            );
            // 普通列（没有子级）
            if (!column.children || column.children.length === 0) {
                displayTree.push({
                    title: column.title,
                    key: nextItem.key,
                    fixed: !!column.fixed,
                });
            }
            // 存在子级的列
            else {
                const col = {
                    title: column.title,
                    key: nextItem.key,
                    fixed: !!column.fixed,
                    children: [],
                };
                nextItem.children.forEach(nextChild => {
                    // 未隐藏，选中
                    if (hiddens.findIndex(hidden => nextChild.key === hidden) < 0) {
                        checkedKeys.push(nextChild.key);
                    }
                    // 实际列
                    const child = column.children.find(
                        child => this._getColumnDataIndex(child) === nextChild.key,
                    );
                    col.children.push({
                        title: child.title,
                        key: nextChild.key,
                    });
                });
                displayTree.push(col);
            }
        });
        this.setState({ displayTree, checkedKeys });
    }

    _updateColumns() {
        const hiddens = this._data.hiddens;
        const nextColumns = [];
        this._data.tree.forEach(nextItem => {
            // 已隐藏跳过
            if (hiddens.find(hidden => nextItem.key === hidden)) {
                return;
            }
            // 实际列
            const column = this.props.columns.find(
                column => this._getColumnDataIndex(column) === nextItem.key,
            );
            // 普通列（没有子级）
            if (!column.children || column.children.length === 0) {
                nextColumns.push({ ...column });
            }
            // 存在子级的列
            else {
                const nextColumn = { ...column, children: [] };
                nextItem.children.forEach(nextChild => {
                    // 已隐藏跳过
                    if (hiddens.find(hidden => nextChild.key === hidden)) {
                        return;
                    }
                    // 实际列
                    const child = column.children.find(
                        child => this._getColumnDataIndex(child) === nextChild.key,
                    );
                    nextColumn.children.push(child);
                });
                nextColumns.push(nextColumn);
            }
        });
        this.props.onChange(nextColumns);
    }

    // 拖拽开始，禁用不可放置选项
    _handleDragStart({ event, node }) {
        const startKey = node.key;
        const nextDisplayTree = [...this.state.displayTree];
        // 第一级
        const inLevel1 = nextDisplayTree.find(item => item.key === startKey);
        if (inLevel1) {
            if (inLevel1.fixed) {
                event.stopPropagation();
                event.preventDefault();
                setTimeout(() => {
                    this._handleDragEnd();
                }, 1800);
            }
            nextDisplayTree.forEach(item => {
                if (item.children && item.children.length > 0) {
                    // 禁用所有子级
                    item.children.forEach(child => {
                        child.disabled = true;
                    });
                }
                // 禁用带有fixed固定属性的列
                if (item.fixed) {
                    item.disabled = true;
                }
            });
        }
        // 第二级
        else {
            nextDisplayTree.forEach(item => {
                // 禁用所有父级
                item.disabled = true;
                if (item.children && item.children.length > 0) {
                    const child = item.children.find(child => child.key === startKey);
                    if (!child) {
                        // 禁用所有其他分组
                        item.children.forEach(child => {
                            child.disabled = true;
                        });
                    }
                }
            });
        }
        this.setState({
            displayTree: nextDisplayTree,
        });
    }

    // 拖拽结束，解除各个选项禁用
    _handleDragEnd() {
        const nextDisplayTree = [...this.state.displayTree];
        nextDisplayTree.forEach(item => {
            item.disabled = false;
            if (item.children && item.children.length > 0) {
                item.children.forEach(child => {
                    child.disabled = false;
                });
            }
        });
        this.setState({
            displayTree: nextDisplayTree,
        });
    }

    _handleDragDrop({ node, dragNode, dropPosition }) {
        const dropPos = node.pos.split('-');
        // 目标位置（-1前、0中、1后）
        const targetPosition = dropPosition - Number(dropPos[dropPos.length - 1]);
        const targetKey = node.key;
        const currentKey = dragNode.key;
        // 是否在第一级
        const inLevel1 = !!this._data.tree.find(item => item.key === currentKey);
        // 在第一级
        if (inLevel1) {
            const currentIndex = this._data.tree.findIndex(
                item => item.key === currentKey,
            );
            let targetIndex = this._data.tree.findIndex(item => item.key === targetKey);
            targetIndex = targetPosition > -1 ? targetIndex + 1 : targetIndex;
            targetIndex = targetIndex < 0 ? 0 : targetIndex;
            Tools.swapArray2(this._data.tree, currentIndex, targetIndex);
            this._updateDisplayTree();
            this._updateColumns();
            this._saveFilterData();
        }
        // 检查第二级
        else {
            this._data.tree.forEach(item => {
                if (!item.children || item.children.length === 0) {
                    return;
                }
                // 是否在第二级
                const inLevel2 = item.children.find(child => child.key === currentKey);
                // 在第二级
                if (inLevel2) {
                    const currentIndex = item.children.findIndex(
                        item => item.key === currentKey,
                    );
                    let targetIndex = item.children.findIndex(
                        item => item.key === targetKey,
                    );
                    targetIndex = targetPosition > -1 ? targetIndex + 1 : targetIndex;
                    targetIndex = targetIndex < 0 ? 0 : targetIndex;
                    Tools.swapArray2(item.children, currentIndex, targetIndex);
                    this._updateDisplayTree();
                    this._updateColumns();
                    this._saveFilterData();
                }
            });
        }
    }

    _handleCheckChange(checkedKeys, { checked, node }) {
        // 选中，取消隐藏
        if (checked) {
            const cancelHidden = key => {
                const index = this._data.hiddens.indexOf(key);
                if (index >= 0) {
                    this._data.hiddens.splice(index, 1);
                }
            };
            cancelHidden(node.key);
            // 如果有子级，所有子级取消隐藏
            if (node.children && node.children.length > 0) {
                node.children.forEach(child => cancelHidden(child.key));
            }
            // 如果是子级，取消父级隐藏
            if (node.pos.split('-').length >= 3) {
                this._data.tree.forEach(item => {
                    if (!item.children || item.children.length === 0) {
                        return;
                    }
                    if (item.children.findIndex(child => child.key === node.key) >= 0) {
                        cancelHidden(item.key);
                    }
                });
            }
        }
        // 取消选择，设置隐藏
        else {
            if (this._data.hiddens.indexOf(node.key) < 0) {
                this._data.hiddens.push(node.key);
            }
            // 如果有子级，所有子级设置隐藏
            if (node.children && node.children.length > 0) {
                node.children.forEach(child => {
                    this._data.hiddens.push(child.key);
                });
            }
        }
        this.setState({ checkedKeys });
        this._updateColumns();
        this._saveFilterData();
    }

    _handleCheckAll() {
        this._data.hiddens = [];
        this.setState({ checkedKeys: this._data.defaultCols });
        this._updateColumns();
        this._saveFilterData();
    }

    _handleResetSort() {
        this._data.tree = this._combineTrees([], this._data.defaultTree);
        this._updateDisplayTree();
        this._updateColumns();
        this._saveFilterData();
    }

    _renderSelectTree() {
        return (
            <div
                className="c-table-filter-drop-inner"
                style={{ maxHeight: this.state.maxHeight }}
            >
                <div className="filter-title">
                    <Checkbox
                        checked={this.state.checkedAll}
                        disabled={this.state.checkedAll}
                        onChange={evt => this._handleCheckAll()}
                    >
                        显示全部
                    </Checkbox>
                    <Button
                        size="small"
                        type="primary"
                        disabled={!this.state.sorted}
                        onClick={evt => this._handleResetSort()}
                    >
                        重置顺序
                    </Button>
                </div>
                <div className="content">
                    <Tree
                        defaultExpandAll={true}
                        blockNode={true}
                        checkable={true}
                        selectable={false}
                        draggable={true}
                        treeData={this.state.displayTree}
                        checkedKeys={this.state.checkedKeys}
                        onDragStart={evt => this._handleDragStart(evt)}
                        onDragEnd={evt => this._handleDragEnd()}
                        onDrop={evt => this._handleDragDrop(evt)}
                        onCheck={(checkedKeys, evt) =>
                            this._handleCheckChange(checkedKeys, evt)
                        }
                    />
                </div>
                <div className="filter-bottom">
                    请<CText type="info"> 勾选 </CText>或<CText type="info"> 拖动 </CText>
                    对列进行调整
                </div>
            </div>
        );
    }

    render() {
        return (
            <Popover
                overlayClassName="c-table-filter-drop"
                content={this._renderSelectTree()}
                placement="bottomRight"
                trigger="click"
            >
                <Tooltip title="调整列" mouseEnterDelay={0}>
                    <Button size="small">
                        {this.state.working ? <FilterFilled /> : <FilterOutlined />}
                    </Button>
                </Tooltip>
            </Popover>
        );
    }
}
