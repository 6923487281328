/**
 * CFitHeightModal
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CDraggableModal } from '@components/plugins/draggableModal/CDraggableModal';
import './cFitHeightModal.scss';

export class CFitHeightModal extends React.Component {
    static propTypes = {
        width: PropTypes.number,
        wrapClassName: PropTypes.string,
        visible: PropTypes.bool,
        style: PropTypes.object,
        children: PropTypes.node,
        onOpened: PropTypes.func,
    };

    static defaultProps = {
        width: 700,
    };

    constructor(props) {
        super(props);
        this.state = {
            height: 'auto',
            width: props.width,
        };
        this.$refs = {};
        this._data = {
            lastVisible: false,
            lastInnerHeight: 0,
            heightTimer: -1,
        };
        this._listeners = {};
    }

    componentDidMount() {
        this._listeners.onResize = () => {
            if (!this.$refs.inner) {
                return;
            }
            const $modelBox = this.$refs.inner.parentElement.parentElement.parentElement;
            const boxHeight = $modelBox.offsetHeight;
            const windowH = window.innerHeight;
            // 根据宽度计算间隔大小
            const spaceH =
                Math.min(100, (window.innerWidth - this.state.width) * 0.3) - 8 * 2;
            // 弹窗限制高度
            const limitH = parseInt(windowH - (spaceH > 0 ? spaceH : 0));
            // 当弹窗高度超出限制高度
            if (boxHeight >= limitH) {
                this.setState({ height: limitH });
            }
            // 当弹窗高度小于限制高度
            else {
                const innerHeight = this.$refs.inner.scrollHeight;
                const modalBodyPaddingTop = parseInt(
                    window
                        .getComputedStyle(this.$refs.inner.parentElement)
                        .getPropertyValue('padding-top'),
                );
                // box 与 inner 间的高度差：最外层上下两个内边距 + 头部高度 + 容器层上内边距
                const deltaHeight = 8 * 2 + 55 + modalBodyPaddingTop;
                // 如果内容超过界面高度
                if (innerHeight + deltaHeight >= limitH) {
                    this.setState({ height: limitH });
                } else {
                    this.setState({ height: innerHeight + deltaHeight });
                }
            }
        };
        setTimeout(this._listeners.onResize, 0);
        window.addEventListener('resize', this._listeners.onResize);
        // 定时检查子级内容的总高度，有变化则重新计算
        this._data.heightTimer = setInterval(() => {
            if (!this._data.lastVisible) {
                return;
            }
            let innerHeight = 0;
            this.$refs.inner.childNodes.forEach(child => {
                innerHeight += child.offsetHeight;
            });
            if (this._data.lastInnerHeight !== innerHeight) {
                this._listeners.onResize();
            }
            this._data.lastInnerHeight = innerHeight;
        }, 500);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._listeners.onResize);
        clearInterval(this._data.heightTimer);
    }

    // 实现打开回调
    _checkModelOnOpened(visible) {
        if (visible !== this._data.lastVisible) {
            if (visible) {
                setTimeout(() => {
                    this._listeners.onResize();
                    this.props.onOpened && this.props.onOpened();
                }, 0);
            }
            this._data.lastVisible = visible;
        }
    }

    render() {
        const { wrapClassName, visible, style, children, ...resetProps } = this.props;
        this._checkModelOnOpened(visible);
        return (
            <CDraggableModal
                {...resetProps}
                wrapClassName={[wrapClassName, 'c-fit-height-modal'].join(' ')}
                centered={true}
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}
                footer={null}
                width={this.state.width}
                style={{ ...style, height: this.state.height }}
                getContainer="#dialogs"
            >
                <div
                    className="c-fit-height-modal-inner"
                    ref={elm => (this.$refs.inner = elm)}
                >
                    {children}
                </div>
            </CDraggableModal>
        );
    }
}
