/**
 * CItemEditor
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import {} from '@ant-design/icons';
import { CFitHeightModal } from '@components/plugins/fitHeightModal/CFitHeightModal';
import { CPlaceholderLink } from '@components/fragments/placeholderLink/CPlaceholderLink';
import './cItemEditor.scss';

export class CItemEditor extends React.Component {
    static propTypes = {
        // 禁用状态
        disabled: PropTypes.bool,
        // 可关闭状态
        closable: PropTypes.bool,
        // 弹窗样式名
        modleClassName: PropTypes.string,
        // 弹窗标题
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        // 弹窗内容
        content: PropTypes.node,
        // 内容类型
        contentType: PropTypes.oneOf(['content', 'iframe']),
        // 弹窗宽度
        width: PropTypes.number,
        // 占位元素样式名
        className: PropTypes.string,
        // 占位符样式
        style: PropTypes.object,
        // 占位元素显示类型
        placeholderType: PropTypes.oneOf([
            'link',
            'linkDanger',
            'button',
            'primary',
            'danger',
            'dashedDanger',
            'none',
        ]),
        // 占位元素为 link 时，显示的图标类型
        iconType: PropTypes.oneOf(['editor', 'viewer']),
        // 占位元素尺寸
        size: PropTypes.oneOf(['small', 'middle', 'large']),
        // 打开前进行条件判断，参数为回调函数
        onBeforeOpen: PropTypes.func,
        // 弹窗开关变化后的回调
        onOpenChange: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
        closable: true,
        width: 600,
        placeholderType: 'link',
        contentType: 'content',
        iconType: 'editor',
        size: 'small',
        onOpenChange: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            iframeLoading: true,
        };
        this._data = {
            openCB: null,
            closeCB: null,
        };
    }

    componentWillUnmount() {
        if (this.state.visible) {
            this._handleCloseEditor();
        }
    }

    _handleCloseEditor() {
        if (this.state.visible) {
            this.setState({
                visible: false,
            });
        }
        setTimeout(() => {
            this.props.onOpenChange(false);
            this._data.closeCB && this._data.closeCB();
        }, 0);
    }

    _handleOpenEditor() {
        this.setState({
            visible: true,
        });
        setTimeout(() => {
            this.props.onOpenChange(true);
            this._data.openCB && this._data.openCB();
        }, 0);
    }

    _handlePlaceholderClick() {
        // 当存在打开前
        if (this.props.onBeforeOpen) {
            this.props.onBeforeOpen(condition => {
                if (condition) {
                    this._handleOpenEditor();
                }
            });
        } else {
            this._handleOpenEditor();
        }
    }

    _renderPlaceholder() {
        switch (this.props.placeholderType) {
            // 链接样式
            case 'link':
            case 'linkDanger':
                // 图标类型
                return (
                    <CPlaceholderLink
                        className={['c-item-editor-link', this.props.className].join(' ')}
                        danger={this.props.placeholderType === 'linkDanger'}
                        disabled={this.props.disabled}
                        style={this.props.style}
                        text={this.props.children}
                        icon={this.props.iconType}
                        onClick={evt => this._handlePlaceholderClick()}
                    />
                );
            // 按钮样式
            case 'button':
            case 'primary':
            case 'danger':
            case 'dashedDanger':
                const type =
                    this.props.placeholderType.indexOf('dashed') >= 0
                        ? 'dashed'
                        : this.props.placeholderType;
                const danger = this.props.placeholderType.indexOf('dashed') >= 0;
                return (
                    <Button
                        className={['c-item-editor-button', this.props.className].join(
                            ' ',
                        )}
                        danger={danger}
                        type={type}
                        size={this.props.size}
                        disabled={this.props.disabled}
                        style={this.props.style}
                        onClick={evt => this._handlePlaceholderClick()}
                    >
                        {this.props.children}
                    </Button>
                );
            // 无占位符
            case 'none':
            default:
                return null;
        }
    }

    _renderContent() {
        if (this.props.contentType === 'iframe') {
            return (
                <>
                    <div
                        className={[
                            'c-item-editor-iframe-loading',
                            this.state.iframeLoading ? '' : 'off',
                        ].join(' ')}
                    >
                        <img src="/static/assets/images/loading-200.gif" alt="" />
                        <span>Loading...</span>
                    </div>
                    <iframe
                        title={Date.now()}
                        src={this.props.content + '&build=' + window.buildDate}
                        onLoad={evt => this.setState({ iframeLoading: false })}
                    ></iframe>
                </>
            );
        } else if (this.props.contentType === 'content') {
            return this.props.content;
        }
    }

    render() {
        return (
            <>
                {this._renderPlaceholder()}
                <CFitHeightModal
                    className={[
                        'c-item-editor-model',
                        this.props.contentType === 'iframe' ? 'c-item-editor-iframe' : '',
                        this.props.modleClassName,
                    ].join(' ')}
                    title={this.props.title}
                    width={this.props.width}
                    closable={this.props.closable}
                    visible={this.state.visible}
                    onCancel={evt => this._handleCloseEditor()}
                >
                    {this._renderContent()}
                </CFitHeightModal>
            </>
        );
    }

    $close() {
        this._handleCloseEditor();
    }

    $open(callback) {
        if (callback) {
            console.warn('CItemEditor: Abandoned Callback!');
        }
        this._data.openCB = callback;
        this._handleOpenEditor();
    }

    $onClose(callback) {
        if (callback) {
            console.warn('CItemEditor: Abandoned Callback!');
        }
        this._data.closeCB = callback;
    }
}
