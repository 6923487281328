/**
 * Fetcher Report
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FSales extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/sales/', '/Home/Sales/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取销售情况列表（销售报表）
    getSalesList(search, pagination, sorter) {
        const url = this.spellURL('getSalesList', '../Report/sales');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取按支付类型的销售详情（销售报表）
    getSalesPayDetail(search, pagination, sorter) {
        const url = this.spellURL('getSalesPayDetail', '../Report/payment_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取产品销售详情（销售报表）
    getSalesDetail(search, pagination, sorter) {
        const url = this.spellURL('getSaleDetail', '../Report/sale_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 所有销售员业绩（销售业绩-按推荐）
    getSalesManPerformance(search, pagination, sorter) {
        const url = this.spellURL('getSalesManPerformance', 'salesman_statistic');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取销售员业绩详情列表（销售业绩-导出）
    getSalesManWorkDetails(search, pagination, sorter) {
        const url = this.spellURL('getSalesManWorkDetails', 'exportMembottle');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 所有销售员业绩（销售业绩-按归属）
    getSalesManBelongings(search, pagination, sorter) {
        const url = this.spellURL('getSalesManBelongings', 'salesperson_statistic');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取销售员新已开户会员（销售业绩）
    getSalesRegisterUserList(search, pagination, sorter) {
        const url = this.spellURL('getSalesRegisterUserList', 'salesman_user_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取销售员新已交押金会员（销售业绩）
    getSalesDepositUserList(search, pagination, sorter) {
        const url = this.spellURL('getSalesDepositUserList', 'salesman_user_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取销售员新已消费会员（销售业绩）
    getSalesConsumptionUserList(search, pagination, sorter) {
        const url = this.spellURL('getSalesConsumptionUserList', 'salesman_user_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取销售统计分析（销售统计）
    getSalesStatistic(search) {
        const url = this.spellURL('getSalesStatistic' + search.type, 'sales_statistic');
        const send = {
            ...this.transKeyName('underline', search),
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取坐席统计列表（坐席统计）
    getSeatJobsCounting(search, pagination, sorter) {
        const url = this.spellURL('getSeatJobsCounting', '../Report/caller_sales_statistic');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取来去电记录（来去点记录）
    getCallsAudioList(search, pagination, sorter) {
        const url = this.spellURL('getCallsAudioList', '../Report/call_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取来电记录（添加订单）
    getCallsAudioListOnAddOrder(search, pagination, sorter) {
        const url = this.spellURL('getCallsAudioList', '../Order/call_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send, [
            ['rows.[]', 'recvName', 'realname'],
        ]);
    }


}

export const $fetchSales = new FSales();

