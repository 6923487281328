/**
 * CToolCurExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import innerText from 'react-innertext';
import { Button, Tooltip, Table, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './cToolCurExport.scss';

export class CToolCurExport extends React.Component {
    static propTypes = {
        // 列设置
        columns: PropTypes.array,
        // 导出文件名
        fileName: PropTypes.string,
        // 当前页码
        pageIndex: PropTypes.number,
        // 当前页数据
        dataSource: PropTypes.array,
        // 操作权限
        permission: PropTypes.bool,
    };

    static defaultProps = {
        fileName: '表格',
        pageIndex: 1,
        columns: [],
        extraColumns: [],
        permission: true,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this._data = {
            // 平化的列设置
            columnsFlat: [],
        };
        this.$refs = {};
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _flatteningColumns() {
        const defaultCol = {
            sorter: false,
            fixed: false,
        };
        this._data.columnsFlat = [];
        this.props.columns.forEach(column => {
            // 普通列（没有子级）
            if (!column.children || column.children.length === 0) {
                this._data.columnsFlat.push({
                    ...column,
                    ...defaultCol,
                });
                // 补充扩充的列
                (this.props.extraColumns || []).forEach(col => {
                    if (column.dataIndex === col.afterIndex) {
                        this._data.columnsFlat.push({
                            ...col,
                            ...defaultCol,
                        });
                    }
                });
            }
            // 存在子级的列
            else {
                column.children.forEach(child => {
                    this._data.columnsFlat.push({
                        ...child,
                        ...defaultCol,
                        // 将两层 title 合并
                        title: '<' + column.title + '>\n' + child.title,
                    });
                    // 补充扩充的列
                    (this.props.extraColumns || []).forEach(col => {
                        if (child.dataIndex === col.afterIndex) {
                            this._data.columnsFlat.push({
                                ...col,
                                ...defaultCol,
                                // 将两层 title 合并
                                title: '<' + column.title + '>\n' + col.title,
                            });
                        }
                    });
                });
            }
        });
    }

    _createFieldDatas(tableData) {
        // 数据
        const rows = tableData.map(rowData => []); // 有多少行
        // 字段名称
        const fields = [];
        // 内容类型
        const contentTypes = [];
        // 提取
        this._data.columnsFlat.forEach((column, index) => {
            // 非数据列跳过
            if (!column.dataIndex) {
                return;
            }
            // 提取字段名称
            fields.push('"' + innerText(column.title) + '"');
            // 提取内容类型
            contentTypes.push(column.export || 'normal');
            // 提取每行数据
            tableData.forEach((rowData, rowIndex) => {
                rows[rowIndex].push(rowData[index]);
            });
        });
        return { fields, rows, contentTypes };
    }

    _createCSV({ fields, rows, contentTypes }) {
        const mulNumberReg = /^\d+(,\d+)+$/;
        const longNumberReg = /^\d{16,}$/;
        // 创建CSV
        let csv = fields.join(',') + '\r\n';
        rows.forEach(row => {
            const rowArr = [];
            row.forEach((col, index) => {
                let content = (col || '').replace(/^\s+|"|\s+$/g, '');
                // 多个数字逗号拼接的字符串或者长度超过15位的纯数字，末尾加一个逗号，防止 excel 转换为科学计数法，丢失后段内容
                if (mulNumberReg.test(content) || longNumberReg.test(content)) {
                    content += ',';
                }
                // 普通内容列
                if (contentTypes[index] === 'normal') {
                    rowArr.push('"' + content + '"');
                }
                // 强制转字符串列，不允许 Excel 转数值
                else if (contentTypes[index] === 'string') {
                    // 如果内容已有逗号，不转换（否则 Excel 将视为列分割符）
                    if (content.indexOf(',') >= 0) {
                        rowArr.push('"' + content + '"');
                    }
                    // 没有逗号，增加等号强制转换
                    else {
                        rowArr.push('="' + content + '"');
                    }
                }
            });
            csv += rowArr.join(',') + '\r\n';
        });
        const bom = '\uFEFF';
        return bom + csv;
    }

    _hanldeExport() {
        if (!this.props.permission) {
            message.error('您没有导出此数据的权限！');
            return;
        }
        const tableData = [];
        this.$refs.container
            .querySelector('.ant-table-tbody')
            .querySelectorAll('.ant-table-row')
            .forEach(row => {
                const rowData = [];
                row.querySelectorAll('.ant-table-cell').forEach(cell => {
                    rowData.push(cell.innerText || cell.textContent);
                });
                tableData.push(rowData);
            });
        const fieldDatas = this._createFieldDatas(tableData);
        const csv = this._createCSV(fieldDatas);
        // 下载
        const blob = new Blob([csv], {
            type: 'text/csv,charset=UTF-8',
            endings: 'native',
        });
        const URL = window.URL || window.webkitURL;
        const csvUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        const date = moment().format('YYYYMMDD.HHmm');
        link.href = csvUrl;
        link.download =
            this.props.fileName + '-第' + this.props.pageIndex + '页-' + date + '.csv';
        link.click();
    }

    _renderTable() {
        this._flatteningColumns();
        return (
            <div className="c-cur-export-table" ref={elm => (this.$refs.container = elm)}>
                <Table
                    columns={this._data.columnsFlat}
                    rowKey={row => row.id || row.key}
                    dataSource={this.props.dataSource}
                    showSorterTooltip={false}
                    pagination={false}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="c-cur-export">
                <Tooltip title="导出当前页面" mouseEnterDelay={0}>
                    <Button size="small" onClick={evt => this._hanldeExport()}>
                        <UploadOutlined className="c-table-cur-export" />
                    </Button>
                </Tooltip>
                {this._renderTable()}
            </div>
        );
    }
}
