/**
 * Fetcher Security
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FSecurity extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/security/', '/Home/Security/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取安全检查基本设置（安检设置）
    getSecurityInfo() {
        const url = this.spellURL('getSecurityInfo', 'security_info');
        const send = {};
        return this.post(url, send);
    }

    // 保存安检配置修改（安检设置）
    saveSecurityInfoChange(info) {
        const url = this.spellURL('saveSecurityInfoChange', 'security_info_update');
        const send = {
            ...this.transKeyName('underline', info),
        };
        return this.post(url, send);
    }

    // 获取安检项目列表（安检设置）
    getSecurityItems(search, pagination, sorter) {
        const url = this.spellURL('getSecurityItems', 'security_item');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取安检项目（筛选项）
    getSecurityItemsAsSelect() {
        const url = this.spellURL('getSecurityItemsFilter', 'security_item');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                const rows2 = (res.rows || [])
                    .filter(item => item.online === 1)
                    .sort((a, b) => (a.typeId > b.typeId && a.level > b.level) ? -1 : 1);
                return rows2.map(item => ({
                    id: item.id,
                    name: item.item,
                }));
            });
    }

    // 保存安检项目是否启用（安检设置）
    saveSecurityItemOnline(online) {
        const url = this.spellURL('saveSecurityItemOnline', 'security_item');
        const send = {
            ...online,
            op: 'switch',
        };
        return this.post(url, send);
    }

    // 保存安检项目（安检设置）
    saveSecurityItem(item) {
        const url = this.spellURL('saveSecurityItem', 'security_item');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(item)),
        };
        return this.post(url, send);
    }

    // 获取安检类型列表（安检设置）
    getSecurityTypes(search, pagination, sorter) {
        const url = this.spellURL('getSecurityTypes', 'security_type');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取安检类型（筛选项）
    getSecurityTypesAsSelect() {
        const url = this.spellURL('getSecurityTypesFilter', 'security_type');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                const rows = res.rows;
                rows.sort((a, b) => a.level > b.level ? -1 : 1);
                return rows.map(item => ({
                    id: item.typeId,
                    name: item.type,
                    disabled: item.status !== 1,
                }));
            });
    }

    // 保存安检类型状态（安检设置）
    saveSecurityTypeStatus(status) {
        const url = this.spellURL('saveSecurityTypeStatus', 'security_type');
        const send = {
            ...status,
            op: 'switch',
        };
        return this.post(url, send);
    }

    // 保存安检类型（安检设置）
    saveSecurityType(item) {
        const url = this.spellURL('saveSecurityType', 'security_type');
        const send = {
            ...this.transKeyName('underline', item),
        };
        return this.post(url, send);
    }

    // 获取安检记录（安检记录）
    getSecurityCheckList(search, pagination, sorter) {
        const url = this.spellURL('getSecurityCheckList', 'security_check');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'time': search.time?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取安检记录-导出地址（安检记录）
    getSecurityCheckExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', 'export_security_check');
        const send = {
            ...search,
            'tel': search.phone,
            'time': search.time?.replace(',', ' - '),
        };
        delete send.phone;
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    // 获取安检自查记录（安检自查）
    getSecuritySelfChecks(search, pagination, sorter) {
        const url = this.spellURL('getSecuritySelfChecks', 'security_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'time': search.time?.replace(',', ' - '),
        };
        return this.post(url, send, [
            ['rows.[]', 'userid', 'userId'],
        ]);
    }

    // 获取安检各项目计数（安检项目统计）
    getSecurityItemCounts(search, pagination, sorter) {
        const url = this.spellURL('getSecurityItemCounts', 'check_item_statistic');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'time': search.time?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取安检各项目计数（安检项目统计新）
    getSecurityItemCountsV2(search) {
        const url = this.spellURL('getSecurityItemCountsV2.' + search.securityType, 'check_item_statistic_new');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取安检配置树目录数据列表（安检配置新）
    getSecurityTreeList(security) {
        const url = this.spellURL('getSecurityTreeList', 'security_rectify');
        const send = {
            ...this.transKeyName('underline', security),
        };
        return this.post(url, send);
    }

    // 获取安检配置树单项信息（安检配置新）
    getSecurityTreeItem(item) {
        const url = this.spellURL('getSecurityTreeItem', 'security_rectify_info');
        const send = {
            ...item,
        };
        return this.post(url, send);
    }

    // 保存安检配置树单项信息（安检配置新）
    saveSecurityTreeItem(item) {
        const url = this.spellURL('saveSecurityTreeItem', 'save_security');
        const { parentId, ...restItem } = this.trimEndsSpace(item);
        const send = {
            ...this.transKeyName('underline', restItem),
            parentId,
        };
        return this.post(url, send);
    }

    // 获取安检配置整改项列表（安检配置新）
    getSecurityRectifyList(item) {
        const url = this.spellURL('getSecurityRectifyList', 'rectification_list');
        const send = {
            ...item,
        };
        return this.post(url, send);
    }

    // 保存安检项绑定整改项（安检配置新）
    saveSecurityBindRectify(item) {
        const url = this.spellURL('saveSecurityBindRectify', 'bind_security');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(item)),
        };
        return this.post(url, send);
    }

    // 获取安检记录列表（安检记录新）
    getSecurityCheckRecords(search, pagination, sorter) {
        const url = this.spellURL('getSecurityCheckRecords', 'new_security_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'datetime': search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取安检单数据（安检记录新）
    getSecurityCheckPrint(check) {
        const url = this.spellURL('getSecurityCheckPrint.2', 'security_order_info');
        const send = {
            ...check,
        };
        return this.post(url, send);
    }

    // 获取安检记录设置（安检记录新）
    getSecurityConfig() {
        const url = this.spellURL('getSecurityConfig', 'setSecurityinfo');
        const send = {
            'is_edit': 0,
        };
        return this.post(url, send);
    }

    // 保存安检记录设置（安检记录新）
    saveSecurityConfig(config) {
        const url = this.spellURL('saveSecurityConfig', 'setSecurityinfo');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(config)),
            'is_edit': 1,
        };
        return this.post(url, send);
    }

    // 获取整改单列表（安检整改单）
    getRectifyList(search, pagination, sorter) {
        const url = this.spellURL('getRectifyList', 'security_correct_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'datetime': search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取整改单详情（安检整改单）
    getRectifyDetail(rectify) {
        const url = this.spellURL('getRectifyDetail', 'security_correct_detail');
        const send = {
            ...this.transKeyName('underline', rectify),
        };
        return this.post(url, send);
    }

    // 保存整改单指派（安检整改单）
    saveRectifyAppoint(appoint) {
        const url = this.spellURL('saveRectifyAppoint', 'appoint_correct');
        const send = {
            ...this.transKeyName('underline', appoint),
        };
        return this.post(url, send);
    }

    // 保存整改单取消（安检整改单）
    saveRectifyCancel(cancel) {
        const url = this.spellURL('saveRectifyCancel', 'cancel_security_correct');
        const send = {
            ...this.transKeyName('underline', cancel),
        };
        return this.post(url, send);
    }

}

export const $fetchSecurity = new FSecurity();

