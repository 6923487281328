/**
 * DirectorOperationStatistic
 * @author Tevin
 */

import moment from 'moment';
import { Director } from '@components/bases/Director';

export class DirectorOperationStatistic extends Director {

    /**
     * 转 Sequence 的标准子项结构
     * @typedef {Object} DataOnDay
     * @property {String} date
     * @property {Number[]} values
     * @example
     *  {
     *      date: '2020-05-15',
     *      values: [ 12, 13, 9 ],
     *  }
     */

    /**
     * 转时间柱状图显示序列
     * @param {DataOnDay[]} dateList
     * @param {string} dateType
     */
    $createSequence(dateList, dateType) {
        const sequence = {
            xAxis: [],
            series: dateList.length > 0 ? dateList[0].values.map(() => []) : [],
        };
        // 按天
        if (dateType === 'date') {
            dateList.forEach(day => {
                sequence.xAxis.push(day.date);
                day.values.forEach((value, index) => {
                    sequence.series[index].push(value);
                });
            });
        }
        // 按周、按月
        else if (dateType === 'week' || dateType === 'month') {
            dateList.forEach(day => {
                const date = moment(day.date);
                let curSlot = '';
                if (dateType === 'week') {
                    curSlot = date.format('YYYY-Wo');
                } else if (dateType === 'month') {
                    curSlot = date.format('YYYY-MM');
                }
                let curIndex = sequence.xAxis.indexOf(curSlot);
                if (curIndex === -1) {
                    curIndex = sequence.xAxis.push(curSlot) - 1;
                }
                day.values.forEach((value, indexSeries) => {
                    if (typeof sequence.series[indexSeries][curIndex] === 'undefined') {
                        sequence.series[indexSeries][curIndex] = value;
                    } else {
                        sequence.series[indexSeries][curIndex] += value;
                    }
                });
            });
        }
        return sequence;
    }

}
