/**
 * Fetcher Account
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree.json';

export class FAccount extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/account/', '/Home/account/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    login(loginData) {
        const url = this.spellURL('loginAccount', '../Index/newLogin');
        const send = {
            account: loginData.userName,
            pwd: loginData.password,
            verify: loginData.verify,
            refer: loginData.refer,
        };
        return this.post(url, send);
    }

    logout(loginData) {
        const url = this.spellURL('logoutAccount', '../Shop/Exitshop');
        const send = {
            ...loginData,
        };
        return this.post(url, send);
    }

    // 后台登陆账号基础信息（个人主页）
    getAccountInfo() {
        const url = this.spellURL('getAccountInfo', '../Shop/getUserInfo');
        const send = {};
        return this.post(url, send);
    }

    // 保存密码（个人主页）
    saveAccountPassword(psw) {
        const url = this.spellURL('saveAccountPassword', '../Setting/user_password');
        const send = {
            'pswd': psw.oldPassword,
            'new_passwd': psw.password,
            'cf_new_passwd': psw.repassword,
        };
        return this.post(url, send);
    }

    // 获取续费信息（个人主页-系统续费）
    getSysRenewalInfo() {
        const url = this.spellURL('getSysRenewalInfo', '../Pay/baseInfo');
        const send = {};
        return this.post(url, send);
    }

    // 获取平台续费支付码（个人主页-系统续费）
    saveSysRenewalPay(renewal) {
        const url = this.spellURL('saveSysRenewalPay', '../Pay/platformPost');
        const send = {
            ...this.transKeyName('underline', renewal),
        };
        return this.post(url, send);
    }

    // 获取续费记录（个人主页）
    getRenewalList() {
        const url = this.spellURL('getRenewalList', '../Pay/get_payment_records');
        const send = {};
        return this.post(url, send);
    }

    // 获取订单支付状态（个人主页-系统续费）
    getPaymentStatus(payment) {
        const url = this.spellURL('getPaymentStatus', '../Pay/getPayStatus');
        const send = {
            ...payment,
        };
        return this.post(url, send);
    }

    // 获取支付订单刷新（个人主页-系统续费）
    getPaymentRefresh(payment) {
        const url = this.spellURL('getPaymentRefresh', '../Pay/refresh_code');
        const send = {
            ...payment,
        };
        return this.post(url, send);
    }

    // 获取app端口记录列表（个人主页-系统续费）
    getAppPortList() {
        const url = this.spellURL('getAppPortList', '../Pay/accountList');
        const send = {};
        return this.post(url, send);
    }

    // 保存端口续费（个人主页-系统续费）
    saveAppPortRenewalPay(payment) {
        const url = this.spellURL('savePortRenewalPay', '../Pay/accountPost');
        const send = {
            ...this.transKeyName('underline', payment),
        };
        return this.post(url, send);
    }

    // 保存添加端口付费（个人主页-系统续费）
    saveAddPortPay(payment) {
        const url = this.spellURL('saveAddPortPay', '../Pay/addAccount');
        const send = {
            ...payment,
        };
        return this.post(url, send);
    }

    // 获取角色类型设置（角色列表）
    getRoleList(search, pagination, sorter) {
        const url = this.spellURL('getRoleList', '../Setting/role');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取账号列表（筛选项）
    getRoleAsSelect() {
        const url = this.spellURL('getRoleList', '../Setting/role');
        const send = {
            page: 1,
            limit: 100,
        };
        return this.post(url, send)
            .then(res => {
            if (!res) {
                return null;
            }
            const rows = res.rows || [];
            return rows.map(item => ({
                id: item.id,
                name: item.name,
            }));
        });
    }

    // 删除角色（角色列表）
    saveDeleteAccountRole(role) {
        const url = this.spellURL('saveDeleteAccountRole', '../Setting/api_role');
        const send = {
            ...role,
        };
        return this.post(url, send);
    }

    // 获取角色权限列表（角色列表）
    getRolePermit(role) {
        const url = this.spellURL('getRolePermit', '../Setting/api_role');
        const send = {
            ...role,
        };
        return this.post(url, send);
    }

    // 保存角色权限列表（角色列表）
    saveRolePermit(permit) {
        const url = this.spellURL('saveRolePermit', '../Setting/api_role');
        const send = {
            ...permit,
        };
        return this.post(url, send);
    }

    // 获取账号列表（账号列表）
    getAccountList(search, pagination, sorter) {
        const url = this.spellURL('getAccountList', '../Setting/employee');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取账号编辑预置信息（账号列表）
    getAccountEditPreset() {
        const url = this.spellURL('getAccountEditPreset', '../Setting/employee_info');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return {
                    emHead: res.emHead,
                    roles: (res.roles || []).map(item => ({
                        value: item.id,
                        name: item.name,
                    })),
                    stores: (res.stores || []).map(item => ({
                        value: item.id,
                        name: item.shopname,
                    })),
                };
            });
    }

    // 保存账号编辑（账号列表）
    saveAccountInfo(account) {
        const url = this.spellURL('saveAccountInfo', '../Setting/api_employee');
        const send = {
            ...this.transKeyName('underline', account),
        };
        return this.post(url, send);
    }

    // 保存账号启用停用（账号列表）
    saveAccountOnlineStatus(status) {
        const url = this.spellURL('saveAccountOnlineStatus', '../Setting/api_employee');
        const send = {
            ...status,
        };
        return this.post(url, send);
    }

    // 获取各账号操作日志
    getAccountsOperateLogs(search, pagination, sorter) {
        const url = this.spellURL('getAccountsOperateLogs', '../Setting/operate_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 系统操作员列表（筛选项）
    getOperatorListAsSelect() {
        const url = this.spellURL('getOperatorListFilter', '../Shop/getSystemEmployeeField');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                const rows = res.rows || [];
                rows.sort((a, b) => a.status > b.status ? -1 : 1);
                return rows.map(item => ({
                    id: item.id,
                    name: item.realname,
                }));
            });
    }

}

export const $fetchAccount = new FAccount();

