/**
 * CMapIFrame
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './cMapIFrame.scss';

export class CMapIFrame extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        loading: PropTypes.bool,
        loadPercent: PropTypes.number,
        onLoadIFrame: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            mapHeight: 450,
        };
        this.$refs = {};
        this._listeners = {};
    }

    componentDidMount() {
        this._listeners.onResize = () => {
            if (!this.$refs.iframe) {
                return;
            }
            // 按需填充，填充到满屏为止
            const containerTop = this.$refs.iframe.getBoundingClientRect().y;
            // 底部距离，内容区内边距 + 窗口区内边距
            const endSideH = 15 + 10;
            // 填充高度
            const fillHeight = window.innerHeight - containerTop - endSideH - 2;
            this.setState({
                mapHeight: fillHeight,
            });
        };
        setTimeout(this._listeners.onResize, 0);
        window.addEventListener('resize', this._listeners.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._listeners.onResize);
    }

    _handleLoaded() {
        this.$refs.iframe.contentWindow.initBadiuMap((bMap, BMap) => {
            this.$refs.iframe.contentWindow.document.body.className = 'service-map';
            this.props.onLoadIFrame({ bMap, BMap });
        });
    }

    _renderPercent() {
        return (
            <Progress
                className="c-baidu-map-percent"
                type="circle"
                width={32}
                strokeWidth={6}
                status="active"
                showInfo={false}
                percent={this.props.loadPercent}
            />
        );
    }

    _renderLoading() {
        return (
            <div
                className={['c-baidu-map-loading', this.props.loading ? 'on' : ''].join(
                    ' '
                )}
            >
                <LoadingOutlined />
                {this._renderPercent()}
            </div>
        );
    }

    render() {
        return (
            <div
                className={['c-baidu-map-box', this.props.className].join(' ')}
                style={{ position: 'relative' }}
            >
                {this._renderLoading()}
                <iframe
                    className="c-baidu-map-iframe"
                    title="baiduMapIFrame"
                    src={
                        '/static/assets/baiduMap/foundation.html?control=parent&build=' +
                        window.buildDate
                    }
                    ref={elm => (this.$refs.iframe = elm)}
                    style={{ height: this.state.mapHeight }}
                    onLoad={evt => this._handleLoaded()}
                ></iframe>
            </div>
        );
    }

    $getMap() {
        if (!this.$refs.iframe) {
            return;
        }
        return this.$refs.iframe.contentWindow.getBaiduMap();
    }
}
