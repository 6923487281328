/**
 * CToolFullExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, message, Modal, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CIconBackExport } from '@components/fragments/icons/CIconBackExport';

export class CToolFullExport extends React.Component {
    static propTypes = {
        // 操作权限
        permission: PropTypes.bool,
        // 获取导出地址
        getExportUrl: PropTypes.func,
    };

    static defaultProps = {
        permission: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _checkTimeAllow() {
        const hour = moment().hour();
        let allow = false;
        if (hour < 9) {
            allow = true;
        } else if (13 <= hour && hour <= 15) {
            allow = true;
        } else if (hour >= 20) {
            allow = true;
        }
        if (!allow) {
            Modal.warning({
                title: '导出失败！',
                content: (
                    <span>
                        请在系统允许的导出时间段内进行此操作！
                        <br />
                        允许时间为：
                        <br />
                        每天 <b>13:00 ~ 15:59</b>
                        <br />
                        每天 <b>20:00 ~ 次日08:59</b>
                    </span>
                ),
            });
        }
        return allow;
    }

    _hanldeExport() {
        if (!this.props.permission) {
            message.error('您没有导出此数据的权限！');
            return;
        }
        if (!this._checkTimeAllow()) {
            return;
        }
        this.setState({ loading: true });
        this.props.getExportUrl(url => {
            this.setState({ loading: false });
            if (!url) {
                return;
            }
            window.open(url);
        });
    }

    render() {
        return (
            <Tooltip
                title={this.state.loading ? '导出中...' : '全部导出 (旧版)'}
                mouseEnterDelay={0}
            >
                <div>
                    <Button
                        size="small"
                        style={{ display: this.state.loading ? 'none' : '' }}
                        onClick={evt => this._hanldeExport()}
                    >
                        <CIconBackExport className="c-table-full-export" />
                    </Button>
                    <Button
                        size="small"
                        style={{ display: this.state.loading ? '' : 'none' }}
                    >
                        <LoadingOutlined spin />
                    </Button>
                </div>
            </Tooltip>
        );
    }
}
