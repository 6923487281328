/**
 * CFlatForm
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './cFlatForm.scss';

export class CFlatForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
        loading: PropTypes.bool,
        // 子级显示方式
        childrenDisplay: PropTypes.oneOf(['inline', 'block']),
        // 完成回调
        onFinish: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.$refs = {};
        this._data = {
            finishCallback: null,
        };
    }

    _renderItems(items) {
        if (!items || items.length === 0) {
            return null;
        }
        return items.map((item, index) => {
            const { render, itemType, children, ...resetProp } = item;
            switch (itemType) {
                case 'noItem':
                    return (
                        <div key={item.name || index} style={item.style}>
                            {render()}
                        </div>
                    );
                case 'children':
                    return (
                        <Form.Item
                            key={index}
                            {...resetProp}
                            className={['c-flat-item-group', resetProp.className].join(
                                ' ',
                            )}
                        >
                            {children.map((child, index2) => {
                                const { render, ...resetChild } = child;
                                return (
                                    <Form.Item
                                        key={index2}
                                        className="c-flat-item-child"
                                        {...resetChild}
                                    >
                                        {child.render()}
                                    </Form.Item>
                                );
                            })}
                        </Form.Item>
                    );
                default:
                    return (
                        <Form.Item key={item.name || index} {...resetProp}>
                            {render()}
                        </Form.Item>
                    );
            }
        });
    }

    render() {
        const { className, items, loading, childrenDisplay, onFinish, ...restProps } =
            this.props;
        return (
            <Spin spinning={this.props.loading} indicator={<LoadingOutlined spin />}>
                <Form
                    className={[
                        'c-flat-form',
                        childrenDisplay === 'block'
                            ? 'c-flat-form-child-block'
                            : 'c-flat-from-child-inline',
                        className,
                    ].join(' ')}
                    scrollToFirstError={true}
                    onFinish={evt => {
                        onFinish && onFinish(evt);
                        this._data.finishCallback && this._data.finishCallback(evt);
                    }}
                    {...restProps}
                    ref={elm => (this.$refs.form = elm)}
                >
                    {this._renderItems(items)}
                </Form>
            </Spin>
        );
    }

    $reset() {
        this.$refs.form.resetFields();
    }

    $submit() {
        this.$refs.form.submit();
    }

    $onFinish(callback) {
        this._data.finishCallback = callback;
    }

    $getFieldValue(name) {
        return this.$refs.form.getFieldValue(name);
    }

    $setFields(data) {
        this.$refs.form.setFields(data);
    }

    $setFieldValue(name, value) {
        const datas = this.$refs.form.getFieldsValue();
        datas[name] = value;
        this.$refs.form.setFieldsValue(datas);
    }

    $setFieldsValue(data) {
        this.$refs.form.setFieldsValue(data);
    }
}
